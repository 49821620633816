import {  GasOrderForm, Order } from "shared";
import { create } from "zustand";

interface OrderStore {
  form: GasOrderForm | null;
  order: Order| null;
  setNewOrder: (form: GasOrderForm | null) => void;
  setOrder: (order: Order| null) => void;
}

export const useOrderStore = create<OrderStore >()((set) => ({
    form: null,
    order: null,
    setOrder: (order: Order| null) => set(() => ({ order })),
    setNewOrder: (form: GasOrderForm | null) => set(() => ({ form })),
}));
