const supplier = "/supplier";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const SupplierPath = {
  supplier,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Supplier_API_PATH = {
  newSupplier: handlePath({
    BasePath: SupplierPath.supplier,
    path: `new`,
  }),
  getSupplies: handlePath({
    BasePath: SupplierPath.supplier,
    path: `/`,
  }),
  requestAllQuota: handlePath({
    BasePath: SupplierPath.supplier,
    path: `/all-quota`,
  }),
  quotaById: (id: string) =>
    handlePath({
      BasePath: SupplierPath.supplier,
      path: `/quota/${id}`,
    }),
};
