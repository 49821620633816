import { AppData } from "shared";
import { create } from "zustand";

interface AppDataStore {
  appData: AppData | null;
  loadedAt: Date | null;
  setData: (appData: AppData | null) => void;
}

export const useAppDataStore = create<AppDataStore>()((set) => ({
    appData: null,
    loadedAt: null,
  setData: (appData: AppData | null) => set(() => ({ appData, loadedAt: new Date() })),
}));
