import React from "react";
import styles from "./footer.module.css";
import { Grid2 } from "@mui/material";
import { CustomLink } from "../link";
import { HEIGHTS } from "../../utils/constants";
import useScreenTypeStore from "store/screenType";
import { ScreenType } from "interface";

const Footer: React.FC = () => {
	const { screenType } = useScreenTypeStore();

	return (
		<footer className={styles.footer} style={{ paddingTop: "20px" }}>
			<div
				style={{
					display: "flex",
					flexDirection: screenType !== ScreenType.Desktop ? "column" : "row",
					justifyContent: "center",
					gap: 50,
				}}
			>
				<Grid2>
					<Grid2 color={"blue"}> Contact Us</Grid2>
					<Grid2>support@urbanlinegas.com </Grid2>
				</Grid2>
				<Grid2>
					<Grid2 color={"blue"}> Address</Grid2>
					<span>After bridge Otoke/Onuembum road</span> <br />
					<span>Or</span> <br />
					<span>After Engoye Junction Otuoke Town, Bayelsa State.</span>
				</Grid2>
				<Grid2>
					<Grid2 color={"blue"}> Company</Grid2>
					<Grid2>
						{" "}
						<CustomLink text={"About us"} to={"/aboutus"} />
					</Grid2>
				</Grid2>
			</div>
			<p>&copy; {new Date().getFullYear()} Urbanline Gas </p>
		</footer>
	);
};

export default Footer;
