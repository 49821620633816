import { DeliveryOption } from "shared";

export const HEIGHTS = {
  footer: 100,
  navBar: 60,
};
export enum ColorEnum {
  Blue = "#144CC3",
}

export const DeliveryDropdownList  = [
  {
  label: "Pickup", 
  value: DeliveryOption.PickUp
},
  {
  label: "Home delivery (coming soon)", 
  value: DeliveryOption.HomeDelivery,
  disabled: true
},
  {
  label: "Pickup by someone", 
  value: DeliveryOption.PickUpBySomeOne
},
]