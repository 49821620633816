export interface Supplier {
  _id: string;
  companyName: string;
  companyEmail: string;
  companyPhoneNumber: string;
  bankAccountNumber: string; // this must be verify  that it belongs to the company
  address: string;
  createdAt: Date;
  suppliesCount: number;
  lastSupplyDate: Date;
  firstSuppliedDate: Date;
  lastSuppliedCost: number;
  contactPerson: string;
  contactPersonPosition: string;
  contactPersonNumber: string;
  status: boolean;
  active: boolean;
  quotaNumber: string;
}

export interface SupplierForm {
  companyName: string;
  companyPhoneNumber: string;
  companyEmail: string;
  contactPerson: string;
  contactPersonPosition: string;
  contactPersonNumber: string;
  bankAccountNumber: string;
  address: string;
}

export interface SupplyForm {
  supplierId: string; // for now no id
  expectedCostPerKg: number;
  expectedSupplyQuantity: number;
  message: string;
  quotaNumber: string;
  quotaId: string;
}

//this form is not connected to any supplier since we didnt connect any supplier yet
export interface GasDeliveryForm {
  costPerKg: number;
  quantity: number;
  message: string;
  paidCompletely: boolean;
  paidAmount: number;
  OtherExpenses: number;
  expensesDescription: string;
  remainingPayment: number;
  quantityBeforeSupply: number;
  imageBeforeSupplyUrl: string;
}
export interface SupplyDeliveryForm {
  supplyId: string;
  suppliedQuantity: number;
  isPaymentCompleted: boolean;
  paidAmount: number;
  costPerKg: number;
  isOtherExpenses: boolean;
  otherExpenses: number;
  expensesDescription: string;
  remainingPayment: number;
  quantityBeforeSupply: number;
  imageBeforeSupplyUrl: string;
}

export interface QuotaAndDeliveryForm extends SupplyDeliveryForm {
  supplierId: string;
}

export interface QuotaForm {
  quantity: number;
  expectedSupplyDate: Date;
  supplierId?: string; // for only single quota
}

export interface SupplierQuota {
  _id: string;
  requestDate: Date;
  costPerKg: number;
  quotaNumber: number;
  availableQuantity: number;
  quantity: number;
  requestedById: string;
  message: string;
  quotas: any[]; // update the quota
  supplierId: string;
  acceptedById: string;
  responseDate: Date;
  acceptedDate: Date;
  createdAt: Date;
  completed: boolean;
}

export interface Quota {
  _id: string;
  supplierId: string;
  requestDate: Date;
  costPerKg: number;
  quotaCompleted: boolean;
  availableQuantity: number;
  quantity: number;
  requestedById: string;
  message: string;
  earliestPossibleSupplyDate: Date;
  expectedSupplyDate: Date;
  quotaNumber: string;
  acceptedSupplierId: string;
  acceptedById: string;
  acceptedDate: Date;
  createdAt: Date;
}

export enum SupplyStatus {
  Delivered = "Delivered",
  WaitingSupplierResponse = "WaitingSupplierResponse",
  Paid = "Paid",
}
