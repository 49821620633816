import { useState, useEffect } from "react";
import { useAppDataStore } from "store/appData";
import { InputField, RadioField } from "../inputs";
import { useAuthStore } from "store/auth";
import { DeliveryOption } from "shared";
import { DeliveryDropdownList } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useOrderStore } from "store";

const LandingPageLive = () => {
  const [quantity, setQuantity] = useState(0);
  const [deliveryOption, setDeliveryOption] = useState(DeliveryOption.PickUp);
  const navigate = useNavigate();

  const [totalCost, setTotalCost] = useState(0);

  const { ...auth } = useAuthStore();
  const { appData } = useAppDataStore();
  const { setNewOrder } = useOrderStore();

  useEffect(() => {
    console.log(appData);
    if (!appData || !appData.currentPrice) {
      return;
    }
    const cost = quantity * appData.currentPrice;
    setTotalCost(cost);
  }, [quantity, appData, deliveryOption]);

  const placeOrder = () => {
    if (!auth.isLogin) {
      setNewOrder({
        pricePerKg: appData?.currentPrice as any,
        quantity,
        clientId: "",
        mobileNumber: "",
        deliveryOption: DeliveryOption.PickUp,
        deliveryTime: null,
        deliveryLocation: null,
      });
      navigate("/login");
      return;
    }
  };
  return (
    <div
      style={{
        padding: "20px",
        paddingTop: 0,
        maxWidth: "400px",
        marginLeft: "0px",
        textAlign: "left",
      }}
    >
      <div style={{ marginBottom: "10px" }}>
        <InputField
          label={"Enter  quantity kg"}
          value={quantity}
          onChange={(value) => setQuantity(value as any)}
          type={"number"}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <RadioField
          label={"Choose delivery option"}
          options={DeliveryDropdownList}
          value={deliveryOption}
          onChange={(value) => setDeliveryOption(value)}
        />
      </div>

      <div style={{ marginBottom: "20px" }}>
        <strong>Total Cost:</strong> <span>{totalCost.toFixed(2)} ₦</span>
      </div>

      <button
        onClick={placeOrder}
        style={{
          padding: "10px 20px",
          backgroundColor: "#28a745",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          width: "100%",
        }}
      >
        Place Order
      </button>
    </div>
  );
};

export default LandingPageLive;
