import { getAppData } from "api/app";
import { getOrderByClientApi } from "api/order";
import { isToday } from "date-fns";
import { useEffect, useState } from "react";
import { Order, PaymentStatus } from "shared";
import { useClientStore } from "store";
import { useAppDataStore } from "store/appData";
import { BodyWithClass } from "ui/typography";

export const LoginHome = () => {
	const { appData, loadedAt, setData } = useAppDataStore();
	const { client } = useClientStore();
	const [pendingOrders, setPendingOrders] = useState<Order[]>([]);
	const [pickupOrders, setPickupOrders] = useState<Order[]>([]);

	useEffect(() => {
		if (!appData) {
			handleAppData();
			return;
		}
		if (loadedAt && !isToday(loadedAt)) {
			handleAppData();
		}
		if (client) {
			orders();
		}
	}, []);

	const handleAppData = () => {
		getAppData()
			.then((data) => {
				setData(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const orders = () => {
		getOrderByClientApi({
			clientId: client?.id as any,
		})
			.then((orders) => {
				setPendingOrders(
					orders.filter(
						(order) => order.paymentStatus !== PaymentStatus.Success
					)
				);
				setPendingOrders(
					orders.filter(
						(order) => order.paymentStatus === PaymentStatus.Success
					)
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<div></div>
			<div></div>
			<div>
				<BodyWithClass
					text={"Are you ready to Order ?"}
					className={"poppins-medium"}
					style={{ fontSize: "12px" }}
				/>
			</div>
		</div>
	);
};
