import { DeliveryOption } from "./delivery";
import { Product } from "./product";
import { Staff } from "./staff";

export * from "./staff";
export * from "./form";
export * from "./supply";
export * from "./product";
export * from "./delivery";

export enum Platform {
  Android = "android",
  IOS = "ios",
  Web = "web",
}
export interface SignupForm {
  // firstname: string;
  // lastname: string;
  name: string;
  mobileNumber: string;
  // platform: Platform;
  email?: string;
}
export interface Client {
  name?: string;
  firstname?: string;
  lastname?: string;
  username: string;
  mobileNumber: string;
  deviceId: string;
  _id: string;
  id: string;
  email?: string;
  isStaff: boolean;
  staff?: Staff;
  cylinder?: Product;
}

export enum OptVerificationType {
  SMS = "SMS",
  Email = "Email",
}

export interface Device {
  id: string;
  name: string;
  model: string;
  builtId: string;
}
export interface CustomRequestHeader {
  device: Device;
  token: string;
}
export interface LoginForm {
  mobileNumber: string;
  verificationType?: OptVerificationType;
  email?: string;
}

export interface LoginResponse {
  token: string;
  isVerified: boolean;
  client: Client;
}

export interface OTPConfirmation {
  optCode: number;
  clientId: string;
  //Todo: we dont need this any
  Platform?: Platform;
  device?: Device;
  platform?: Platform;
}

export interface FileUploadResponse {
  fileUrl: string;
  fileName: string;
  fileId: string;
}

export interface OptResponseDta {
  expiredAt: Date;
}
export interface Auth {
  clientId?: string;
  isVerified: boolean;
  isLogin: boolean;
  lastLogin?: Date;
  token?: string;
  mobileNumber?: string;
  optData?: OptResponseDta;
}

export interface DataInterface {
  client: Client;
  staff?: Staff;
}

export interface AppData {
  message: string;
  currentPrice: number;
  previousPrice: number;
  remainingQuantity: number;
  lastUpdated: Date;
  isOpen: boolean;
}

export enum OrderPickUpDispute {
  None = "none",
  SomeOneElseClaim = "SomeOneElseClaim",
  InvalidPickup = "InvalidPickup ",
}

export interface Order {
  _id: string;
  clientId: string;
  paymentId: number;
  orderNumber: number;
  qrCodeToken: string;
  payment: any;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  paymentSuccess: boolean;
  paymentNumber: number;
  pricePerKg: number;
  quantityKg: number;
  totalAmount: number;
  isDelivered: boolean;
  issuedById: Number;
  orderType: OrderType;
  // deliveredBy: String;
  deliveredDate: Date;
  deliveredOption: DeliveryOption;
  shouldDeliverAt: Date;
  deliveryCenter: DeliverCenters;
  pickupDispute: OrderPickUpDispute;
  delivery: ""; // add delivery option
  createdAt: Date;
  status: OrderStatus;
  clientConfirmedPickup: boolean;
  clientConfirmPickupDate: Date;
}

export interface OrderApiFilterParam {
  clientId: string;
  orderStatus?: OrderStatus;
  fromDate?: Date;
  toDate?: Date;
  paymentStatus?: PaymentStatus;
}
export enum OrderType {
  Gas = "Gas",
  Product = "Product",
  GasAndProduct = "Gas and Product",
}
export enum DeliverCenters {
  Company = "Urbanline_gas",
}

export enum OrderStatus {
  NotPaid = "Not Paid",
  Processing = "Processing",
  Paid = "Paid",
  Fail = "Failed",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
}

export enum PaymentType {
  Cash = "Cash",
  Electronic = "Electronic",
  Wallet = "Wallet",
}

export enum PaymentStatus {
  Abandoned = "abandoned",
  Failed = "failed",
  Ongoing = "ongoing",
  Pending = "pending",
  Processing = "processing",
  Queued = "queued",
  Reversed = "reversed",
  Success = "success",
}
export enum PaymentGateway {
  PayStack = "PayStack",
  Wallet = "Wallet",
}

export enum PaymentPurpose {
  Order = "Order",
  Wallet = "Wallet",
}
export interface Payment {
  _id: string;
  orderId?: number;
  clientId: string;
  amount: number;
  date: Date;
  gatewayTransactionId: string;
  gatewayReference: string;
  status: PaymentStatus;
  paymentGateway: PaymentGateway;
  success: boolean;
  invoiceId: string;
  purpose: PaymentPurpose;
  paymentId: number;
  walletId?: number;
}

export interface Sale {
  _id: string;
  orderId?: number;
  staffId: number;
  amount: number;
  date: Date;
  status: PaymentStatus;
  success: boolean;
  paymentId: number;
}
export interface Sales {
  sales: Sale[];
  totalAmount: number;
  count: number;
}

export enum WalletTransactionStatus {
  Pending = "pending",
  Completed = "completed",
  Failed = "failed",
  Success = "success",
}

export enum WalletTransactionType {
  Debit = "Debit",
  Credit = "Credit",
}
export interface WalletTransaction {
  _id: string;
  clientId: string;
  amount: number;
  walletNumber: number;
  createdAt: Date;
  status: WalletTransactionStatus;
  type: WalletTransactionType;
  source: {};
}
export interface Wallet {
  _id: string;
  clientId: String;
  lastAdded: Date;
  balance: number;
  lastUsed: Date;
  transactions: WalletTransaction[];
  walletNumber: number;
  createdAt: Date;
}
