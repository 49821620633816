// api.js
import axios, { isAxiosError } from "axios";
import { API_PATH } from "shared";
// const isProduct = process.env.NODE_ENV === "production";

// const getApiBaseUrl = () => {
//   return "http://localhost:8080/api"; // iOS simulator and development
// };

export const AxiosApi = axios.create({
  // baseURL: "http://localhost:8080/api",
  baseURL: API_PATH.base_uri,
  timeout: 10000, // You can set a timeout if needed
  withCredentials: true, // Move this here

  headers: {
    platform: "web",
    "Content-Type": "application/json",
  },
});
