const upload = "/upload";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const FileUploadPath = {
  fileUpload: upload,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const FILE_UPLOAD_API_PATH = {
  uploadFile: handlePath({ BasePath: upload, path: "file" }),
};
