import { OrderStatus } from "../interface";

const admin = "/admin";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const AdminPath = {
  admin,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const ADMIN_API_PATH = {
  //   orderList: handlePath({
  //     BasePath: AdminPath.admin,
  //     path: `new`,
  //   }),
  orderList: (status: OrderStatus, fromDate?: Date, toDate?: Date) =>
    handlePath({
      BasePath: admin,
      path: `orders/${status}/${fromDate}/${toDate}`,
    }),
  pickup: (orderId: number) =>
    handlePath({
      BasePath: admin,
      path: `pickup/${orderId}`,
    }),

  startShift: (badgeNumber: number) =>
    handlePath({
      BasePath: admin,
      path: `start_shift/${badgeNumber}`,
    }),
  endShift: (badgeNumber: number) =>
    handlePath({
      BasePath: admin,
      path: `end_shift/${badgeNumber}`,
    }),

  getShift: (id: number) =>
    handlePath({
      BasePath: admin,
      path: `shift/${id}`,
    }),

  getOngoingShift: (badgeNumber: number) =>
    handlePath({
      BasePath: admin,
      path: `ongoing_shift/${badgeNumber}`,
    }),

  getAllShift: () =>
    handlePath({
      BasePath: admin,
      path: `shift`,
    }),

  mySales: () =>
    handlePath({
      BasePath: admin,
      path: `sales/staff`,
    }),
  sales: () =>
    handlePath({
      BasePath: admin,
      path: `sales`,
    }),
  salesByStaff: (staff: number) =>
    handlePath({
      BasePath: admin,
      path: `sales/${staff}`,
    }),
  //   getOrdersByClient: (clientId: string) =>
  //     handlePath({
  //       BasePath: OrderPath.order,
  //       path: `client/${clientId}`,
  //     }),

  //   confirmPayment: (orderId: string) =>
  //     handlePath({
  //       BasePath: OrderPath.order,
  //       path: `confirm_payment/${orderId}`,
  //     }),

  //   verifyOrderPayment: handlePath({
  //     BasePath: OrderPath.order,
  //     path: `verify`,
  //   }),
};
