import { Client } from "shared";
import { create } from "zustand";

interface ClientStore {
  client: Client | null;
  setClient: (user: Client | null) => void;
  logonStore: () => void;
}

export const useClientStore = create<ClientStore>()((set) => ({
  client: null,
  setClient: (client: Client | null) => set(() => ({ client })),
  logonStore: () => set(() => ({ client: null })),
}));
