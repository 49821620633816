import React, { ChangeEvent, useState, FC, CSSProperties } from "react";
import {
	TextField,
	MenuItem,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";

interface InputFieldProps {
	label: string;
	value: any;
	disabled?: boolean;
	required?: boolean;
	error?: boolean;
	helperText?: string;
	placeholder?: string;
	style?: CSSProperties;
	type: "email" | "password" | "text" | "number" | "search" | "tel";
	onChange?: (value: string) => void;
}

export const InputField: FC<InputFieldProps> = ({
	label,
	value,
	type = "text",
	error = false,
	disabled = false,
	helperText,
	required = false,
	placeholder,
	...props
}) => {
	const { onChange, style } = props;

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<TextField
			label={label}
			style={{ padding: "7px", ...style }}
			type={type}
			value={value}
			disabled={disabled}
			onChange={handleChange}
			variant="outlined"
			margin="normal"
			color="success"
			placeholder={placeholder}
			fullWidth
			required={required}
			autoComplete={type}
			error={error}
			helperText={helperText}
		/>
	);
};

interface SelectFieldProps {
	label: string;
	value: any;
	options: { label: string; value: any }[];
	disabled?: boolean;
	required?: boolean;
	error?: boolean;
	helperText?: string;
	placeholder?: string;
	onChange?: (value: any) => void;
}

export const SelectField: FC<SelectFieldProps> = ({
	label,
	value,
	options,
	error = false,
	disabled = false,
	helperText,
	required = false,
	placeholder,
	...props
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { onChange } = props;
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<TextField
			select
			label={label}
			value={value}
			disabled={disabled}
			onChange={handleChange}
			variant="outlined"
			margin="normal"
			color="success"
			placeholder={placeholder}
			fullWidth
			required={required}
			error={error}
			helperText={helperText}
		>
			{options.map((option) => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};

interface RadioFieldProps {
	label: string;
	value: any;
	options: { label: string; value: any; disabled?: boolean }[];
	disabled?: boolean;
	required?: boolean;
	error?: boolean;
	helperText?: string;
	onChange?: (value: any) => void;
}

export const RadioField: FC<RadioFieldProps> = ({
	label,
	value,
	options,
	error = false,
	disabled = false,
	helperText,
	required = false,
	...props
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { onChange } = props;
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<FormControl
			component="fieldset"
			error={error}
			disabled={disabled}
			required={required}
		>
			<FormLabel component="legend">{label}</FormLabel>
			<RadioGroup value={value} onChange={handleChange}>
				{options.map((option) => (
					<FormControlLabel
						key={option.value}
						value={option.value}
						control={<Radio />}
						label={option.label}
						disabled={option.disabled}
					/>
				))}
			</RadioGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
