import Footer from "ui/Footer";
import { Body, Header } from "../../ui/typography";
import LandingPageLive from "./LandingPageLive";
import useScreenTypeStore from "../../store/screenType";
import { ScreenType } from "../../interface";
import { useEffect } from "react";
import { Btn } from "ui/button";
import { CustomLink } from "ui/link";

const LandingPage = () => {
	const { screenType } = useScreenTypeStore();
	useEffect(() => {}, [screenType]);

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					maxWidth: "1280px",
					paddingRight: screenType === ScreenType.Desktop ? "64px" : "30px",
					paddingLeft: screenType === ScreenType.Desktop ? "64px" : "30px",
					paddingTop: screenType === ScreenType.Desktop ? "64px" : "30px",
					flexDirection: "column",
					margin: "auto",
					gap: "50px",
					marginBottom: "50px",
				}}
			>
				<div
					style={{
						display: "flex",
						gap: "100px",
					}}
				>
					<div style={{}}>
						<Header text={"Your gas supply at the comfort of your home!"} />
						<div>
							<Body
								text={"Reliable Gas Supply to Your Doorstep, Just a Click Away"}
							/>
							<Body text={"You order we deliver to your doorstep"} />
						</div>
					</div>
					{screenType === ScreenType.Desktop && (
						<div style={{ width: "50%" }}>
							<img style={{ width: "100%" }} src="/image/ug_home.svg" />
						</div>
					)}
				</div>

				<div>
					{/* <Header text={"Live update"} /> */}
					<div>
						<LandingPageLive />
					</div>
				</div>

				<div
					style={{
						display: "flex",
						// justifyContent: "center",
						flexDirection: screenType !== ScreenType.Desktop ? "column" : "row",
					}}
				>
					{screenType === ScreenType.Desktop && (
						<div style={{ width: "50%" }}>
							<img
								style={{ width: "100%" }}
								src="/image/order.svg"
								alt="order"
							/>
						</div>
					)}

					<div style={{}}>
						<Header text={"Order on App!"} />
						<div>
							<Body text={"One click to order from your app"} />
						</div>
					</div>
					{screenType !== ScreenType.Desktop && (
						<div style={{}}>
							<img
								style={{ width: "100%" }}
								src="/image/order.svg"
								alt="order"
							/>
						</div>
					)}
				</div>

				<div
					style={{
						display: "flex",
						// justifyContent: "center",
						flexDirection: screenType === ScreenType.Desktop ? "row" : "column",
						gap: screenType === ScreenType.Desktop ? "100px" : "5px",
					}}
				>
					<div style={{}}>
						<Header text={"Not ready to pay all? "} />
						<div>
							<Body text={"Consider our installment payment options."} />
							<Body text={"You can spread your payments over time."} />
						</div>
						<div style={{ textAlign: "left" }}>
							<CustomLink text={"Get started"} to={"/login"} />
						</div>
					</div>
					<div style={{}}>
						<img
							style={{ width: "100%" }}
							src="/image/payment.svg"
							alt="payment"
						/>
					</div>
				</div>

				<div
					style={{
						display: "flex",
						justifyContent: "center",
						gap: "100px",
					}}
				>
					{/* <div style={{ width: "50%" }}>
						<img
							style={{ width: "100%" }}
							src="/image/app.svg"
							alt="download"
						/>
					</div> */}
					<div style={{ width: "50%" }}>
						<Header text={"Download our app"} />
						<div>
							<Body text={"Our app is coming soon"} />
						</div>
					</div>
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</>
	);
};

export default LandingPage;
