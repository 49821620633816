const supply = "/supply";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const SupplyPath = {
  supply,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Supply_API_PATH = {
  newGasSupply: handlePath({
    BasePath: SupplyPath.supply,
    path: `new`,
  }),
  supplyDelivery: handlePath({
    BasePath: SupplyPath.supply,
    path: `delivery`,
  }),
  newAndDelivery: handlePath({
    BasePath: SupplyPath.supply,
    path: `new_delivery`,
  }),
};
