import * as React from "react";
import { Link } from "react-router-dom";

export const CustomLink: React.FC<{
	text: string;
	to: string;
	color?: string;
}> = ({ text, to, color }) => {
	return (
		<div>
			<Link style={{ color }} to={to}>
				{text}
			</Link>
		</div>
	);
};
