import {
	Avatar,
	Box,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	useMediaQuery,
	useTheme,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import React, { useEffect } from "react";
import { Btn } from "../button";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import Products from "./products";
import { useAuthStore, useClientStore } from "store";

const Account: React.FC = () => {
	const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);
	const { client } = useClientStore();
	const auth = useAuthStore();
	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if (client && auth.isLogin) {
			setIsUserLoggedIn(auth.isLogin);
		}
	}, [client, auth]);

	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
		null
	);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<Box sx={{ right: 0 }}>
			<Box
				sx={{
					flexGrow: 0,
					display: { xs: "flex", md: "none" },
				}}
			>
				{isUserLoggedIn ? (
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						{/* <Avatar alt={user?.username} src={user?.photo} /> */}
					</IconButton>
				) : (
					<IconButton
						size="large"
						edge="end"
						color="inherit"
						onClick={handleOpenUserMenu}
						aria-label="menu"
						sx={{ mr: -2, color: "white" }}
					>
						<MenuIcon />
					</IconButton>
				)}

				<Menu
					sx={{ mt: "30px" }}
					id="menu-appbar"
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					<div style={{ marginRight: "20px" }}>
						{isUserLoggedIn ? (
							<MenuItem onClick={handleCloseUserMenu}>
								<Btn
									text="logout"
									onClick={handleCloseUserMenu}
									type="primary"
									style={{ backgroundColor: "BLACK" }}
								/>
							</MenuItem>
						) : (
							<>
								<Products />
								<MenuItem onClick={handleCloseUserMenu}>
									<Btn
										text="Login"
										onClick={() => navigate("/login")}
										type="primary"
										style={{ backgroundColor: "BLACK" }}
									/>
								</MenuItem>
							</>
						)}
					</div>
				</Menu>
			</Box>
			{/* //for large screen */}
			<Box
				sx={{
					flexGrow: 0,
					display: { xs: "none", md: "flex" },
				}}
			>
				{isUserLoggedIn ? (
					<Tooltip title="Open settings">
						<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
							{/* <Avatar alt={user?.firstname} src={user?.photo} /> */}
						</IconButton>
					</Tooltip>
				) : (
					<div
						style={{
							flexGrow: 0,
							display: "flex",
							justifyContent: "flex-end",
							maxWidth: "100%",
							gap: "20px",
						}}
					>
						<Btn
							text="Login "
							style={{ backgroundColor: "black" }}
							onClick={() => navigate("/login")}
						/>
						<Btn
							text="Signup"
							style={{ backgroundColor: "black" }}
							onClick={() => navigate("/signup")}
						/>
					</div>
				)}
			</Box>
		</Box>
	);
};

export default Account;
