import { isAxiosError } from "axios";
import { Order, OrderApiFilterParam, } from "shared/interface";
import { GasOrderForm, VerifyOrder } from "shared/interface/form";
import { AxiosApi } from ".";
import { API_PATH } from "shared";


export const newOrderApi = async (form: GasOrderForm) => {
  try {
    const _data = await AxiosApi.post<Order>(`/order/new`, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrderByClientApi = async (
    filter: OrderApiFilterParam
) => {
  try {
    const _data = await AxiosApi.get<Order[]>( API_PATH.order.getOrdersByClient(filter.clientId).frontend , {
      params: {
       ...filter
    }});
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrderByIdApi = async (orderId: string) => {
  try {
    const _data = await AxiosApi.get<Order>(`/order/${orderId}`);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const cancelOrderByIdApi = async (orderId: string) => {
  try {
    const _data = await AxiosApi.get<Order>(`/order/cancel/${orderId}`);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const confirmOrderPaymentApi = async (orderId: string) => {
  try {
    const _data = await AxiosApi.get<Order>(
      `/order/confirm_payment/${orderId}`
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const verifyOrderPaymentApi = async (form: VerifyOrder) => {
  try {
    const _data = await AxiosApi.post<Order>(`/order/verify/`, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getOrderByPaymentNumberApi = async (paymentNumber: number) => {
  try {
    const _data = await AxiosApi.get<Order>(
      `/order/payment_number/` + paymentNumber
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
