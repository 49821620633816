export class CustomError implements ICustomError {
  message!: string;
  status!: number;
  additionalInfo!: any;
  action!: any; //
  type?: ErrorType;
  constructor(
    message: string,
    status: number = 500,
    additionalInfo: any = undefined,
    errorType: ErrorType = ErrorType.Unknown
  ) {
    this.message = message;
    this.status = status;
    this.additionalInfo = additionalInfo;
    this.type = errorType;
  }
}

export interface IResponseError {
  message: string;
  additionalInfo?: string;
}

export interface ICustomError {
  message?: string;
  status?: number;
  additionalInfo?: any;
  action?: ErrorTAction; //
  type?: ErrorType;
}

export enum ErrorType {
  UserExist = "UserExist",
  NoUserFound = "NoUserFound",
  SignUpFailed = "SignUpFailed",
  Unknown = "Unknown",
  InsufficientFund = "InsufficientFund ",
  LoginFailed = "loginFailed",
  OPTSendFailed = "OPTSendFailed",
  OTPExpired = "OTPExpired",
  MobileNumberMissing = "MobileNumberMissing",
  InvalidOTPCode = "InvalidOTPCode",
  ConfirmVerificationFailed = "ConfirmVerificationFailed",
  RequestOPTCodeFailed = "RequestOPTCodeFailed",
  CookiesAuthFailed = "CookiesAuthFailed",
  UnAuthorized = "UnAuthorized",
  TokenVerificationError = "TokenVerificationError",
  TokenExpired = "TokenExpired",
  OrderPaymentFailed = "OrderPaymentFailed",
  OrderPaymentError = "OrderPaymentError",
  CantVerifyOrderPayment = "CantVerifyOrderPayment",
  PaidAmountNotMatch = "PaidAmountNotMatch",
  UpdateGasSupplyFailed = "UpdateGasSupplyFailed",

  ///----project----
}

export enum ErrorTAction {
  Other = "",
}
