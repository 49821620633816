import React from "react";
import { Link } from "react-router-dom";

const DriverSignupPage = () => {
  return (
    <div style={styles.container as any}>
      <h1 style={styles.title}>Join Our Team of Drivers</h1>
      <p style={styles.subtitle}>
        Become part of our growing community of delivery professionals. Earn
        income while delivering gas safely and reliably to customers in your
        area.
      </p>
      <img
        src="https://via.placeholder.com/600x300" // Replace with an actual image URL
        alt="Driver Delivering Gas"
        style={styles.image}
      />

      <div style={styles.buttonContainer as any}>
        <Link to="/" style={styles.signupButton}>
          Sign Up Now
        </Link>
        <p style={styles.loginText}>
          Already have an account?{" "}
          <Link to="/" style={styles.loginLink}>
            Log In
          </Link>
        </p>
      </div>

      <div>
        <p>Comming soon...... stay tune.</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    textAlign: "center",
    height: "100vh",
    backgroundColor: "#f9f9f9",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
  },
  subtitle: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "30px",
    maxWidth: "600px",
    lineHeight: "1.5",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    marginBottom: "30px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
  },
  signupButton: {
    textDecoration: "none",
    backgroundColor: "#007BFF",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "5px",
    transition: "background-color 0.3s",
    cursor: "pointer",
  },
  signupButtonHover: {
    backgroundColor: "#0056b3",
  },
  loginText: {
    fontSize: "14px",
    color: "#555",
  },
  loginLink: {
    color: "#007BFF",
    textDecoration: "underline",
    cursor: "pointer",
  },
};

export default DriverSignupPage;
