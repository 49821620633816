export enum ProductType {
  Cylinder = "Cylinder",
  Burner = "Burner",
  GasStove = "GasStove",
}

export interface Product {
  id: string;
  name: string;
  price: number;
  size: number;
  color: string;
  imageUrl: string;
  quantity: number;
  productId: number;
  productType: ProductType;
  description: string;
}
export interface ProductSales extends Product {
  clientId: string;
  orderDate: Date;
  deliverDate: Date;
}
