import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomError, ErrorType } from "shared";
import { optRequestApi, optVerificationApi } from "../../api/auth";
import { useAuthStore, useClientStore } from "../../store";
import { Btn } from "../../ui/button";
import { CustomLink } from "../../ui/link";
import { Body, ErrorMessage } from "../../ui/typography";
import { HEIGHTS } from "../../utils/constants";
const otpWaitingTime = 170;
const OTPVerification: React.FC = () => {
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const [timeLeft, setTimeLeft] = useState<number>(otpWaitingTime);
  const [clientMissing, setClientMissing] = useState(false);
  const [error, setError] = useState("");

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { type } = useParams();
  const auth = useAuthStore();
  const navigate = useNavigate();
  const { client, setClient } = useClientStore();

  useEffect(() => {
    if (client && auth.isLogin) {
      navigate("/");
    }
  }, [client, auth]);

  const handleChange = (value: string, index: number) => {
    if (clientMissing) return;
    if (!/^\d?$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index: number) => {
    const newOtp = [...otp];
    if (newOtp[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
      newOtp[index - 1] = "";
    } else {
      newOtp[index] = "";
    }
    setOtp(newOtp);
  };

  // Handle OTP pasting
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").slice(0, 6); // Get up to 6 digits
    if (/^\d+$/.test(pastedData)) {
      const newOtp = Array(6).fill("");
      for (let i = 0; i < pastedData.length; i++) {
        newOtp[i] = pastedData[i];
      }
      setOtp(newOtp);
      inputRefs.current[pastedData.length - 1]?.focus(); // Focus last filled input
    }
  };

  useEffect(() => {
    if (!auth.clientId) {
      setClientMissing(true);
      setError("Something went wrong in verification, please go to login.");
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [auth]);

  const handleResendCode = () => {
    requestNewOtp();
  };

  const onVerify = () => {
    const otpString = otp.join("");
    if (otpString.length < 6) {
      setError("Please enter the full OTP code.");
      return;
    }

    optVerificationApi({
      optCode: otpString as any,
      clientId: auth.clientId as any,
    })
      .then((response) => {
        setClient(response.client);
        auth.setAuth({
          isVerified: true,
          isLogin: true,
        });
        console.log("Verification successful:", response);
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        setError("Something went wrong,  go back to login");
        if (error) {
          const err = error as CustomError;
          if (
            err.type === ErrorType.InvalidOTPCode ||
            err.type === ErrorType.OTPExpired
          ) {
            setError("OTP has expired or invalid was provide, pls try");
            return;
          }
        }
      });
  };

  const requestNewOtp = () => {
    optRequestApi({})
      .then((response) => {
        console.log("sent ", response);
        if (timeLeft === 0) {
          setOtp(Array(6).fill(""));
          setTimeLeft(otpWaitingTime);
        }
      })
      .catch((error) => {
        setError("Something went wrong,  go back to login");
      });
  };
  return (
    <div
      className="body-width"
      style={{
        height: `calc(100vh - ${HEIGHTS.footer}px - ${HEIGHTS.navBar}px - 90px)`,
      }}
    >
      <div style={{ ...(styles.container as any) }}>
        <h1 style={styles.title}>Enter Verification Code</h1>
        <p style={styles.subtitle}>
          We have sent a verification code to your phone number.
        </p>
        <div style={styles.otpContainer}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              value={digit}
              maxLength={1}
              className="otp-input"
              style={{ ...styles.otpInput }}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => e.key === "Backspace" && handleBackspace(index)}
              onPaste={handlePaste}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        <Btn
          text="Verify"
          onClick={onVerify}
          isDisabled={clientMissing || otp.some((digit) => digit === "")}
        />
        <p style={styles.resendText}>
          {timeLeft > 0 ? (
            `Resend code in 0:${timeLeft.toString().padStart(2, "0")}`
          ) : (
            <span onClick={handleResendCode} style={styles.resendLink}>
              Resend Code
            </span>
          )}
        </p>
        {error && <ErrorMessage text={error} />}
        <CustomLink text="Back to Login" to="/login" />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    color: "#666",
    textAlign: "center" as "center",
    marginBottom: "20px",
  },
  otpContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "15px",
  },
  otpInput: {
    width: "40px",
    height: `50px`,
    fontSize: "18px",
    textAlign: "center" as "center",
    borderRadius: "8px",
    border: "1px solid #ddd",
  },
  resendText: {
    fontSize: "14px",
    color: "#666",
  },
  resendLink: {
    color: "#007BFF",
    textDecoration: "underline",
    cursor: "pointer",
  },
};

export default OTPVerification;
