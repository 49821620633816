export enum StaffAccess {
  Admin = "Admin",
  Staff = "Staff",
  Manager = "Manager",
  CEO = "CEO",
}

export interface Staff {
  badgeNumber: number;
  // _id: string;
  id: string;
  firstname: string;
  lastname: string;
  previousWorkDate: Date;
  accountNumber: string;
  mobileNumber: string;
  employDate: Date;
  access: StaffAccess[];
  clientId: string;
  isCurrentStaff: boolean;
  staffReview: string;
}

export interface StaffForm {
  firstname: string;
  lastname: string;
  accountNumber: string;
  mobileNumber: string;
  access: StaffAccess;
}

export interface PickUpData {
  orderId: number;
  name: string;
  mobileNumber: string;
}

export interface Shift {
  _id: any;
  startTime: Date;
  endTime: Date;
  totalHours: number;
  badgeNumber: number;
  message: string;
  workingNow: boolean;
  startingQuantity: number;
  closingQuantity: Number;
  TotalSalesQuantity: number;
  pricePerKg: number;
}
