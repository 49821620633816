const quota = "/quota";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const QuotaPath = {
  quota,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${QuotaPath.quota}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Quota_API_PATH = {
  createSingleSupplierQuota: handlePath({
    path: `single_supplier`,
  }),
  createAllSupplier: handlePath({
    path: `/all_supplier`,
  }),
  allQuota: handlePath({
    path: `/`,
  }),
};
