import React from "react";
import LandingPage from "../ui/landingPage/LandingPages";
import { useClientStore } from "store";
import { LoginHome } from "ui/landingPage/Home";

const Home = () => {
	const { client } = useClientStore();

	return <div>{client ? <LoginHome /> : <LandingPage />}</div>;
};

export default Home;
