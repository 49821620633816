//import { api_prefix } from "./index";

const client = "/client";
const auth = "/auth";
const order = "/auth";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const ClientPath = {
  client,
  auth,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const CLIENT_API_PATH = {
  meetingBooking: handlePath({
    BasePath: ClientPath.client,
    path: `new_meeting`,
  }),

  newOrder: handlePath({
    BasePath: ClientPath.client,
    path: `new_order`,
  }),

  clientByEmail: (email: string) =>
    handlePath({
      BasePath: ClientPath.client,
      path: `user/` + email,
    }),
};

//------------ Auth ----------//

export const AUTH_API_PATH = {
  signUp: handlePath({
    BasePath: ClientPath.auth,
    path: `signup`,
  }),

  login: handlePath({
    BasePath: ClientPath.auth,
    path: `login`,
  }),

  authByCookie: handlePath({
    BasePath: ClientPath.auth,
    path: `cookie_auth`,
  }),

  logout: handlePath({
    BasePath: ClientPath.auth,
    path: `logout`,
  }),

  optCodeByClientId: handlePath({
    BasePath: ClientPath.auth,
    path: `opt_by_client`,
  }),
  optRequest: handlePath({
    BasePath: ClientPath.auth,
    path: `opt_request`,
  }),
  optConfirmation: handlePath({
    BasePath: ClientPath.auth,
    path: `opt_confirmation`,
  }),
};
