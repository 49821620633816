import { isAxiosError } from "axios";
import { AppData, StaffForm } from "shared/interface";
import { AxiosApi } from ".";
import { API_PATH } from "shared";

export const addEmployeeApi = async (form: StaffForm): Promise<any> => {
  try {
    const _data = await AxiosApi.post(API_PATH.app.getAppData.frontend, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getAppData = async () => {
  try {
    const _data = await AxiosApi.get<AppData>(API_PATH.app.getAppData.frontend);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
