import { isAxiosError } from "axios";
import {
  Auth,
  LoginForm,
  LoginResponse,
  OptResponseDta,
  OTPConfirmation,
} from "shared/interface";
import { AxiosApi } from ".";
import { API_PATH } from "shared";

export const signupApi = async ({
  mobileNumber,
}: {
  mobileNumber: string;
}): Promise<Auth> => {
  try {
    const _data = await AxiosApi.post<Auth>(API_PATH.auth.signUp.frontend, {
      mobileNumber,
    });
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const loginApi = async (form: LoginForm): Promise<Auth> => {
  try {
    const _data = await AxiosApi.post<Auth>(API_PATH.auth.login.frontend, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error.request.body);
      throw error.response?.data;
    }
    throw error;
  }
};

export const optVerificationApi = async (
  form: OTPConfirmation
): Promise<LoginResponse> => {
  try {
    const _data = await AxiosApi.post<LoginResponse>(
      API_PATH.auth.optConfirmation.frontend,
      form
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const optRequestApi = async ({
  mobileNumber,
}: {
  mobileNumber?: string;
}) => {
  try {
    const _data = await AxiosApi.post<OptResponseDta>(
      API_PATH.auth.optRequest.frontend,
      {
        mobileNumber,
      }
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
