import { Button, Divider, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../style.module.css";
import { CustomLink } from "../../ui/link";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../ui/inputs";
import { Body, ErrorMessage, Header } from "../../ui/typography";
import { loginApi } from "../../api/auth";
import { ColorEnum } from "../../utils/constants";
import { CustomError, ErrorType } from "shared";
import { useAuthStore } from "../../store/index";

function Login() {
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const { setAuth } = useAuthStore();
	const navigate = useNavigate();

	const handleNumberChange = (value: string) => {
		if (mobileNumber.length > 11) return;
		const noTextRegex = /^[^a-zA-Z]*$/;
		if (noTextRegex.test(value)) {
			setMobileNumber(value);
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (mobileNumber.length < 9) {
			setError("Mobile number is not complete");
			return;
		}

		loginApi({ mobileNumber })
			.then((data) => {
				setAuth(data);
				navigate("/auth/verification/mobile", {
					state: { type: "mobile", mobileNumber },
				});
			})
			.catch((error) => {
				if (error) {
					const err = error as CustomError;
					if (err.type === ErrorType.UserExist) {
						setError("Account with this detail exist, pls login");
						return;
					}
				}
				setError("Something went wrong in creating account");
			});
	};

	return (
		<div
			style={{ height: "100%", margin: "auto", marginTop: "60px", width: 400 }}
			className="body-width"
		>
			<Header text={"Login"} />

			<form onSubmit={handleSubmit} className={styles.formContainer}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Body text={"Login with your mobile number"} />
					<Divider style={{ maxWidth: "40%" }} />
				</div>

				<InputField
					value={mobileNumber}
					label="Mobile number"
					placeholder="070000000"
					type="tel"
					required={true}
					error={error !== ""}
					onChange={handleNumberChange}
				/>

				<Button type="submit" fullWidth variant="contained">
					{" "}
					Continue
				</Button>

				<div
					style={{
						display: "flex",
						// justifyContent: "end",
						marginTop: "15px",
						gap: "5px",
					}}
				>
					<span>Dont have an account? </span>

					<CustomLink to="/Signup" text="Signup" color={ColorEnum.Blue} />
				</div>

				{error && <ErrorMessage text={error} />}
			</form>
		</div>
	);
}

export default Login;
