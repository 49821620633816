//import { api_prefix } from "./index";

const wallet = "/wallet";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const WalletPath = {
  wallet,
};

const api_prefix = "api";

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Wallet_API_PATH = {
  walletByClient: handlePath({
    BasePath: WalletPath.wallet,
    path: `client`,
  }),
  addFund: handlePath({
    BasePath: WalletPath.wallet,
    path: `add_fund`,
  }),
  verifyPayment: handlePath({
    BasePath: WalletPath.wallet,
    path: `verify_payment`,
  }),
};
