//import { api_prefix } from "./index";

const order = "/order";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const OrderPath = {
  order,
};

const api_prefix = "api";

const handlePath = ({  path }: {  path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${order}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Order_API_PATH = {
  newOrder: handlePath({
    path: `new`,
  }),
  getOrderById: (orderId: string) =>
    handlePath({
      path: orderId,
    }),
  getOrdersByClient: (clientId: string) =>
    handlePath({
      path: `client/${clientId}`,
    }),
  cancelOrder: (orderId: string) =>
    handlePath({
      path: `cancel/${orderId}`,
    }),

  confirmPayment: (orderId: string) =>
    handlePath({
      path: `confirm_payment/${orderId}`,
    }),

  getQrCode: (code: string) =>
    handlePath({
      path: `qr_code/${code}`,
    }),

  getOrderByPaymentNumber: (paymentNumber: string) =>
    handlePath({
      path: `payment_number/${paymentNumber}`,
    }),

  verifyOrderPayment: handlePath({
    path: `verify`,
  }),
};
