import { ScreenType } from '../interface';
import { create } from 'zustand';

interface ScreenTypeStore {
  screenType: ScreenType;
  setScreenType: (type: ScreenType) => void;
  updateScreenType: () => void;
}

const useScreenTypeStore = create<ScreenTypeStore>((set) => ({
  screenType: ScreenType.Desktop, // default
  setScreenType: (type) => set({ screenType: type }),
  updateScreenType: () => {
    const width = window.innerWidth;
    let type = ScreenType.Desktop;

    if (width < 768) {
      type = ScreenType.Mobile;
    } else if (width < 1024) {
      type = ScreenType.Tablet;
    }
    set({ screenType: type });
  },
}));

export default useScreenTypeStore;