const payment = "/payment";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const PaymentPath = {
  payment,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${PaymentPath.payment}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Payment_API_PATH = {
  walletAddFund: handlePath({
    path: `initiate/add_found`,
  }),
  orderPayment: handlePath({
    path: `initiate/order`,
  }),
  list: handlePath({
    path: `/`,
  }),
  //this will be replace by the two init above
  initiate: handlePath({
    path: "initiate",
  }),

  sendPayment: handlePath({
    path: "send_payment",
  }),

  paymentByWallet: handlePath({
    path: `wallet/`,
  }),
  getPaymentByOrder: (orderId: string) =>
    handlePath({
      path: `order/${orderId}`,
    }),

  getPayment: (id: string) =>
    handlePath({
      path: `${id}`,
    }),
};
